import React from "react";

import { IContentModuleLeadooBotFields } from "../../types/generated/contentful";

const LeadooBot: React.FC<IContentModuleLeadooBotFields> = ({
  leadooBotName,
}) => {
  return (
    <div data-testid="leadoo-container" className="pb4">
      <ld-slot data-testid="leadoo-slot" name={leadooBotName} />
    </div>
  );
};

export default LeadooBot;
