import classNames from "classnames";
import { Entry } from "contentful";
import React, { ReactNode } from "react";

import {
  IContentModuleHero,
  IContentModuleGrid,
  IContentModuleRichText,
  IContentModuleNavigationGroup,
  IContentModuleHighlight,
  IContentModuleHighlightCollection,
  IContentModuleLeadooBot,
  IContentModuleLogo,
  IContentModuleLogoCollection,
  IContentModulePageHero,
  IContentModuleTable,
  IContentModuleAttractionCategoryDisplay,
  IContentModuleVideo,
  IContentModuleImageGallery,
  IContentModuleButton,
  IContentModuleFeatureContainer,
  IContentModuleProductListing,
  IContentModuleInfoContainer,
  IContentModuleRideSearch,
  IContentModuleImage,
  IContentModuleContactCollection,
  IContentModuleOpeningHours,
  IContentModuleRestaurantSearch,
  IContentModuleGridModal,
  IContentModuleSocialMediaWall,
  IContentModuleAnchorNavigation,
  IContentModuleEmbeddedContent,
  ILink,
  IPage,
  IAttraction,
  IRestaurant,
  IEmailSubscriptionForm,
} from "../../types/generated/contentful";
import EmailSubscriptionForm from "../EmailSubscriptionForm";

import { AnchorNavigation } from "./AnchorNavigation";
import AttractionCategoryDisplay from "./AttractionCategoryDisplay";
import Button from "./Button";
import ContactCollection from "./ContactCollection";
import EmbeddedContent from "./EmbeddedContent";
import FeatureContainer from "./FeatureContainer";
import Grid from "./Grid";
import GridModal from "./GridModal";
import Hero from "./Hero";
import Highlight from "./Highlight";
import HighlightCollection from "./HighlightCollection";
import Image from "./Image";
import ImageGallery from "./ImageGallery";
import InfoContainer from "./InfoContainer";
import LeadooBot from "./LeadooBot";
import Logo from "./Logo";
import LogoCollection from "./LogoCollection";
import NavigationGroup from "./NavigationGroup";
import OpeningHours from "./OpeningHours";
import PageHero from "./PageHero";
import ProductListing from "./ProductListing";
import RestaurantSearch from "./RestaurantSearch";
import RichText from "./RichText";
import RideSearch from "./RideSearch";
import { SocialMediaWall } from "./SocialMediaWall";
import Table from "./Table";
import Video from "./Video";

type ContentModule =
  | IContentModuleHero
  | IContentModuleGrid
  | IContentModuleNavigationGroup
  | IContentModuleHighlight
  | IContentModuleHighlightCollection
  | IContentModuleLeadooBot
  | IContentModuleLogo
  | IContentModuleLogoCollection
  | IContentModulePageHero
  | IContentModuleRichText
  | IContentModuleTable
  | IContentModuleAttractionCategoryDisplay
  | IContentModuleVideo
  | IContentModuleImageGallery
  | IContentModuleButton
  | IContentModuleFeatureContainer
  | IContentModuleProductListing
  | IContentModuleInfoContainer
  | IContentModuleRideSearch
  | IContentModuleImage
  | IContentModuleContactCollection
  | IContentModuleOpeningHours
  | IContentModuleRestaurantSearch
  | IContentModuleGridModal
  | IContentModuleSocialMediaWall
  | IContentModuleAnchorNavigation
  | IContentModuleEmbeddedContent
  | IEmailSubscriptionForm;

interface ContentModulesProps {
  className?: string;
  contentModules: ContentModule | ContentModule[];
}

export function isModuleHero(
  entry: Entry<unknown>,
): entry is IContentModuleHero {
  const typeName: IContentModuleHero["sys"]["contentType"]["sys"]["id"] =
    "contentModuleHero";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleGrid(
  entry: Entry<unknown>,
): entry is IContentModuleGrid {
  const typeName: IContentModuleGrid["sys"]["contentType"]["sys"]["id"] =
    "contentModuleGrid";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleNavigationGroup(
  entry: Entry<unknown>,
): entry is IContentModuleNavigationGroup {
  const typeName: IContentModuleNavigationGroup["sys"]["contentType"]["sys"]["id"] =
    "contentModuleNavigationGroup";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleHighlightCollection(
  entry: Entry<unknown>,
): entry is IContentModuleHighlightCollection {
  const typeName: IContentModuleHighlightCollection["sys"]["contentType"]["sys"]["id"] =
    "contentModuleHighlightCollection";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleHighlight(
  entry: Entry<unknown>,
): entry is IContentModuleHighlight {
  const typeName: IContentModuleHighlight["sys"]["contentType"]["sys"]["id"] =
    "contentModuleHighlight";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleLeadooBot(
  entry: Entry<unknown>,
): entry is IContentModuleLeadooBot {
  const typeName: IContentModuleLeadooBot["sys"]["contentType"]["sys"]["id"] =
    "contentModuleLeadooBot";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleLogo(
  entry: Entry<unknown>,
): entry is IContentModuleLogo {
  const typeName: IContentModuleLogo["sys"]["contentType"]["sys"]["id"] =
    "contentModuleLogo";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleLogoCollection(
  entry: Entry<unknown>,
): entry is IContentModuleLogoCollection {
  const typeName: IContentModuleLogoCollection["sys"]["contentType"]["sys"]["id"] =
    "contentModuleLogoCollection";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModulePageHero(
  entry: Entry<unknown>,
): entry is IContentModulePageHero {
  const typeName: IContentModulePageHero["sys"]["contentType"]["sys"]["id"] =
    "contentModulePageHero";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleRichText(
  entry: Entry<unknown>,
): entry is IContentModuleRichText {
  const typeName: IContentModuleRichText["sys"]["contentType"]["sys"]["id"] =
    "contentModuleRichText";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleTable(
  entry: Entry<unknown>,
): entry is IContentModuleTable {
  const typeName: IContentModuleTable["sys"]["contentType"]["sys"]["id"] =
    "contentModuleTable";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleAttractionCategoryDisplay(
  entry: Entry<unknown>,
): entry is IContentModuleAttractionCategoryDisplay {
  const typeName: IContentModuleAttractionCategoryDisplay["sys"]["contentType"]["sys"]["id"] =
    "contentModuleAttractionCategoryDisplay";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleVideo(
  entry: Entry<unknown>,
): entry is IContentModuleVideo {
  const typeName: IContentModuleVideo["sys"]["contentType"]["sys"]["id"] =
    "contentModuleVideo";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleImageGallery(
  entry: Entry<unknown>,
): entry is IContentModuleImageGallery {
  const typeName: IContentModuleImageGallery["sys"]["contentType"]["sys"]["id"] =
    "contentModuleImageGallery";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleButton(
  entry: Entry<unknown>,
): entry is IContentModuleButton {
  const typeName: IContentModuleButton["sys"]["contentType"]["sys"]["id"] =
    "contentModuleButton";
  return entry?.sys?.contentType?.sys?.id === typeName;
}

export function isModuleFeatureContainer(
  entry: Entry<unknown>,
): entry is IContentModuleFeatureContainer {
  const typename: IContentModuleFeatureContainer["sys"]["contentType"]["sys"]["id"] =
    "contentModuleFeatureContainer";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleProductListing(
  entry: Entry<unknown>,
): entry is IContentModuleProductListing {
  const typename: IContentModuleProductListing["sys"]["contentType"]["sys"]["id"] =
    "contentModuleProductListing";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleInfoContainer(
  entry: Entry<unknown>,
): entry is IContentModuleInfoContainer {
  const typename: IContentModuleInfoContainer["sys"]["contentType"]["sys"]["id"] =
    "contentModuleInfoContainer";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleRideSearch(
  entry: Entry<unknown>,
): entry is IContentModuleRideSearch {
  const typename: IContentModuleRideSearch["sys"]["contentType"]["sys"]["id"] =
    "contentModuleRideSearch";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleImage(
  entry: Entry<unknown>,
): entry is IContentModuleImage {
  const typename: IContentModuleImage["sys"]["contentType"]["sys"]["id"] =
    "contentModuleImage";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleContactCollection(
  entry: Entry<unknown>,
): entry is IContentModuleContactCollection {
  const typename: IContentModuleContactCollection["sys"]["contentType"]["sys"]["id"] =
    "contentModuleContactCollection";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleOpeningHours(
  entry: Entry<unknown>,
): entry is IContentModuleOpeningHours {
  const typename: IContentModuleOpeningHours["sys"]["contentType"]["sys"]["id"] =
    "contentModuleOpeningHours";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleRestaurantSearch(
  entry: Entry<unknown>,
): entry is IContentModuleRestaurantSearch {
  const typename: IContentModuleRestaurantSearch["sys"]["contentType"]["sys"]["id"] =
    "contentModuleRestaurantSearch";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleGridModal(
  entry: Entry<unknown>,
): entry is IContentModuleGridModal {
  const typename: IContentModuleGridModal["sys"]["contentType"]["sys"]["id"] =
    "contentModuleGridModal";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleSocialMediaWall(
  entry: Entry<unknown>,
): entry is IContentModuleSocialMediaWall {
  const typename: IContentModuleSocialMediaWall["sys"]["contentType"]["sys"]["id"] =
    "contentModuleSocialMediaWall";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleAnchorNavigation(
  entry: Entry<unknown>,
): entry is IContentModuleAnchorNavigation {
  const typename: IContentModuleAnchorNavigation["sys"]["contentType"]["sys"]["id"] =
    "contentModuleAnchorNavigation";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleEmbeddedContent(
  entry: Entry<unknown>,
): entry is IContentModuleEmbeddedContent {
  const typename: IContentModuleEmbeddedContent["sys"]["contentType"]["sys"]["id"] =
    "contentModuleEmbeddedContent";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleLink(entry: Entry<unknown>): entry is ILink {
  const typename: ILink["sys"]["contentType"]["sys"]["id"] = "link";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModulePage(entry: Entry<unknown>): entry is IPage {
  const typename: IPage["sys"]["contentType"]["sys"]["id"] = "page";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleAttraction(
  entry: Entry<unknown>,
): entry is IAttraction {
  const typename: IAttraction["sys"]["contentType"]["sys"]["id"] = "attraction";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleRestaurant(
  entry: Entry<unknown>,
): entry is IRestaurant {
  const typename: IRestaurant["sys"]["contentType"]["sys"]["id"] = "restaurant";
  return entry?.sys?.contentType?.sys?.id === typename;
}

export function isModuleEmailSubscriptionForm(
  entry: Entry<unknown>,
): entry is IEmailSubscriptionForm {
  const typename: IEmailSubscriptionForm["sys"]["contentType"]["sys"]["id"] =
    "emailSubscriptionForm";
  return entry?.sys?.contentType?.sys?.id === typename;
}

function handle<T extends ContentModule>(
  guard: (entry: ContentModule) => entry is T,
  handler: (m: T) => ReactNode,
): (data: ContentModule) => ReactNode | undefined {
  return (data: ContentModule): ReactNode | undefined =>
    guard(data) ? handler(data) : undefined;
}

const mappings: Array<(data: ContentModule) => ReactNode | undefined> = [
  handle(isModuleHero, data => <Hero {...data.fields} />),
  handle(isModuleGrid, data => <Grid {...data.fields} />),
  handle(isModuleNavigationGroup, data => <NavigationGroup {...data.fields} />),
  handle(isModuleHighlightCollection, data => (
    <HighlightCollection {...data.fields} />
  )),
  handle(isModuleHighlight, data => <Highlight {...data.fields} />),
  handle(isModuleLeadooBot, data => <LeadooBot {...data.fields} />),
  handle(isModuleLogo, data => <Logo {...data.fields} />),
  handle(isModuleLogoCollection, data => <LogoCollection {...data.fields} />),
  handle(isModulePageHero, data => <PageHero {...data.fields} />),
  handle(isModuleRichText, data => (
    <div className="flex justify-center h-100">
      <RichText {...data.fields} />
    </div>
  )),
  handle(isModuleTable, data => <Table {...data.fields} />),
  handle(isModuleAttractionCategoryDisplay, data => (
    <AttractionCategoryDisplay {...data.fields} />
  )),
  handle(isModuleVideo, data => <Video {...data.fields} />),
  handle(isModuleImageGallery, data => <ImageGallery {...data.fields} />),
  handle(isModuleButton, data => (
    <div className="mv4 ph3 tc">
      <Button {...data.fields} />
    </div>
  )),
  handle(isModuleFeatureContainer, data => (
    <FeatureContainer {...data.fields} />
  )),
  handle(isModuleProductListing, data => <ProductListing {...data.fields} />),
  handle(isModuleInfoContainer, data => <InfoContainer {...data.fields} />),
  handle(isModuleRideSearch, data => <RideSearch {...data.fields} />),
  handle(isModuleImage, data => <Image {...data.fields} />),
  handle(isModuleContactCollection, data => (
    <ContactCollection {...data.fields} />
  )),
  handle(isModuleOpeningHours, data => <OpeningHours {...data.fields} />),
  handle(isModuleRestaurantSearch, data => (
    <RestaurantSearch {...data.fields} />
  )),
  handle(isModuleGridModal, data => <GridModal {...data.fields} />),
  handle(isModuleSocialMediaWall, data => <SocialMediaWall {...data.fields} />),
  handle(isModuleAnchorNavigation, data => (
    <AnchorNavigation {...data.fields} />
  )),
  handle(isModuleEmbeddedContent, data => <EmbeddedContent {...data.fields} />),
  handle(isModuleEmailSubscriptionForm, data => (
    <EmailSubscriptionForm {...data.fields} />
  )),
];

function renderContentModule(module: ContentModule): ReactNode {
  for (const mapping of mappings) {
    const result = mapping(module);
    if (result) {
      return result;
    }
  }
  return null;
}

const ContentModules: React.FC<ContentModulesProps> = ({
  className,
  contentModules,
}) => {
  const modules = Array.isArray(contentModules)
    ? contentModules
    : [contentModules];
  const elements = modules.map((module, i) => (
    <section
      key={`${module.sys.id}#${i}`}
      className={classNames(module?.sys?.contentType?.sys?.id, className)}
      data-testid="content-module-container"
    >
      {renderContentModule(module)}
    </section>
  ));
  return <>{elements}</>;
};

export default ContentModules;
